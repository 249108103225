import logo from "./logo.svg";
import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";

function App() {
  const [data, setData] = useState([]);
  const [userInput, setUserInput] = useState({
    Name: "",
    Age: "",
    Address: "",
  });

  const { Name, Age, Address } = userInput;

  const handleChange = (e) => {
    setUserInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    const data = {
      Name,
      Age,
      Address,
    };
    axios
      .post("http://localhost:3002/input", data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get("http://localhost:3002/")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input name="Name" value={Name} onChange={handleChange} />
        <input name="Age" value={Age} onChange={handleChange} />
        <input name="Address" value={Address} onChange={handleChange} />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
